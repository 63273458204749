<template>
  <v-container fluid class='align-self-sm-stretch justify-space-between'>
    <v-col class='pa-0 py-3'>
      <v-tooltip bottom>
        <template #activator='{ on }'>
          <v-icon v-on='on' @click='$emit("close")'>
            mdi-arrow-left-circle
          </v-icon>
        </template>
        {{ $t('BACK') }}
      </v-tooltip>
    </v-col>
    <v-col>
      <v-row>
        <v-autocomplete
          ref='autocomplete'
          v-model='selectedStations'
          item-text='name'
          return-object
          multiple
          chips
          color='primary'
          deletable-chips
          clearable
          :items='stations'
          :readonly='lockAutocomplete'
          :search-input='autocompleteSearchInput'
          :label='$t("STATION")'
        />
      </v-row>
      <v-row>
        <v-autocomplete
          ref='autocomplete'
          v-model='selectedOperators'
          item-text='name'
          return-object
          multiple
          chips
          color='primary'
          deletable-chips
          clearable
          :items='operators'
          :label='$t("OPERATOR")'
          :readonly='lockAutocomplete'
          :search-input='autocompleteSearchInput'
        />
      </v-row>
      <v-row>
        <span class='subtitle-2'>{{ $t('CREATION_DATE') }}</span>
        <v-col cols='12'>
          <v-row class='d-flex align-center'>
            <span class='mr-2 mt-4'>{{ $t('FROM') }}: </span>
            <CustomDatePicker @change='setStartDate' />
          </v-row>
        </v-col>
        <v-col cols='12'>
          <v-row class='d-flex align-center'>
            <span class='mr-2 mt-4'>{{ $t('UNTIL') }}: </span>
            <CustomDatePicker @change='setEndDate' />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col class='card-footer'>
      <v-btn
        color='secondary'
        dark
        @click='applyFilter()'
      >
        {{ $t('SAVE') }}
      </v-btn>
    </v-col>
  </v-container>
</template>
<script>
  import axios from 'axios';

  export default {
    props: {
      allLocations: {
        type: Array,
        required: true,
      },
    },
    components: {
      CustomDatePicker: () => import('../../components/DatePicker/CustomDatePicker.vue'),
    },
    data () {
      return {
        operators: [],
        selectedOperators: [],
        stations: [],
        autocompleteData: null,
        lockAutocomplete: false,
        autocompleteSearchInput: '',
        selectedStations: [],
        startDate: null,
        endDate: null,
      };
    },
    mounted: function () {
      this.fetchData();
      this.getAccounts();
    },
    methods: {
      fetchData: async function () {
        this.stations = this.allLocations;
      },
      getAccounts: async function () {
        const { data } = await axios({
          url: '/account-get-all',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.operators = data.list;
      },
      applyFilter: function () {
        const filters = {
          stationsFilter: this.selectedStations,
          operatorsFilter: this.selectedOperators.map((so) => so.account_id),
          dateFilter: {
            startDate: this.startDate,
            endDate: this.endDate,
          },
        };
        this.$emit('applyFilters', filters);
      },
      setStartDate (date) {
        this.startDate = date;
        if (date) {
          this.startDateFromPicker = `${date} 00:00:00`;
          this.startDate = `${date} 00:00:00`;
        }
      },
      setEndDate (date) {
        this.endDate = date;
        if (date) {
          this.endDateFromPicker = `${date} 23:59:59`;
          this.endDate = `${date} 23:59:59`;
        }
      },
    },
  };

</script>
<style>
  .selected-chip {
    border-color: var(--v-secondary-base) !important;
    color: #FFFFFF !important;
    background-color: var(--v-accent-base) !important;
  }
  .selected-chip span {
    color: var(--v-secondary-base) !important;
  }
  .card-footer {
    text-align: end;
  }
</style>
